import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      color: 'black',
      backgroundColor: '#abdef7'

    }}
  >
    <div
      style={{
        maxWidth: 1200,
        margin: '0 auto 0 auto',
        textAlign: 'left',
        paddingTop: '1.5rem',
        paddingBottom: '0.5rem'
      }}
    >
      <h1 style={{ marginLeft: 10 }}>
        <Link
          to="/"
          style={{
           color: 'black',
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
